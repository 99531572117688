// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
// A Stimulus controller to create new fields on the fly to populate 
// Rails relationship with accepts_nested_attributes_for
import NestedForm from "stimulus-rails-nested-form";

window.Stimulus = Application.start();
const context = require.context("./", true, /_controller\.js$/);
Stimulus.load(definitionsFromContext(context));
Stimulus.register("nested-form", NestedForm);

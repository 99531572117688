import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form'];

  connect() {}

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);
    this.formTarget.classList.add('was-validated');

    if (!isValid) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  validateForm() {
    let isValid = true;
    let requiredFieldSelectors = 'input:required';
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();
        isValid = false;
      }
    });

    return isValid;
  }
}

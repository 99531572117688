// initializes iot range slider callbacks so page does not need to be reloaded - known turbo/js issue
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {

  connect() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    this.rangeChange()
  }

  rangeChange() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    let target = this.element.getAttribute('data-value-display-selector')
    $(target).html(this.element.value)
  }
}

import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = [
    "brightness",
    "effect",
    "entity_name",
    "temperature",
    "hvac",
    "fan",
    "low",
    "high",
    "preset",
    "aux",
    "swing",
    "red",
    "green",
    "blue",
    "colorTemp",
    "flash"
  ]
  connect() {
    // console.log("Hello, Stimulus!", this.element)
  }
  toggle() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
  }
  updateBrightness() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const brightness = this.brightness()
    this.brightnessTarget.value = brightness
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_brightness?brightness=${brightness}`).then((response) => {

    })
  }
  brightness() {
    return parseInt(this.brightnessTarget.value)
  }
  updateEffect() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const effect = this.effect()
    this.effectTarget.value = effect
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_effect?effect=${effect}`).then((response) => {
    })
  }
  effect() {
    return this.effectTarget.value
  }
  flashLight() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const flash = this.flash()
    this.flashTarget.value = flash
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/flash_light?flash=${flash}`).then((response) => {
    })
  }
  flash() {
    return this.flashTarget.value
  }
  updateColorTemp() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const temp = this.colorTemp()
    this.colorTempTarget.value = temp
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_color_temp?color_temp=${temp}`).then((response) => {

    })
  }
  colorTemp() {
    return parseInt(this.colorTempTarget.value)
  }
  setRgbColor() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const red = this.red()
    this.redTarget.value = red
    const green = this.green()
    this.greenTarget.value = green
    const blue = this.blue()
    this.blueTarget.value = blue
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_color?red=${red}&green=${green}&blue=${blue}`).then((response) => {
    })
  }
  red() {
    return this.redTarget.value
  }
  green() {
    return this.greenTarget.value
  }
  blue() {
    return this.blueTarget.value
  }
  updateName() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const entity_name = this.entityName()
    this.nameTarget.value = entity_name
    return fetch(`/admin/api/iot/entity/${iot_entity_id}/update_entity_name?name=${entity_name}`).then((response) => {
    })
  }
  entityName() {
    return this.nameTarget.value
  }
  updateTemperature() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const temperature = this.temperature()
    this.temperatureTarget.value = temperature
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_temperature?temperature=${temperature}`).then((response) => {
    })
  }
  temperature() {
    return parseInt(this.temperatureTarget.value)
  }
  updateTargetTempRange() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const low = this.target_low()
    const high = this.target_high()
    this.lowTarget.value = low
    this.highTarget.value = high
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_target_temp_range?target_temp_low=${low}&target_temp_high=${high}`).then((response) => {
    })
  }
  target_low() {
    return parseInt(this.lowTarget.value)
  }
  target_high() {
    return parseInt(this.highTarget.value)
  }
  updateFan() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const fan = this.fanMode()
    this.fanTarget.value = fan
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_fan_mode?fan_mode=${fan}`).then((response) => {
    })
  }
  fanMode() {
    return this.fanTarget.value
  }
  updateHvac() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const hvac = this.hvacMode()
    this.hvacTarget.value = hvac
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_hvac_mode?hvac_mode=${hvac}`).then((response) => {
    })
  }
  hvacMode() {
    return this.hvacTarget.value
  }
  updatePreset() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const preset = this.presetMode()
    this.presetTarget.value = preset
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_preset_mode?preset_mode=${preset}`).then((response) => {
    })
  }
  presetMode() {
    return this.presetTarget.value
  }
  updateAuxHeat() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const aux = this.auxMode()
    this.auxTarget.value = aux
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_aux_heat?aux_heat=${aux}`).then((response) => {
    })
  }
  auxMode() {
    return this.auxTarget.value
  }
  updateSwingMode() {
    let iot_entity_id = this.element.getAttribute('data-entity-id')
    const swing = this.swingMode()
    this.swingTarget.value = swing
        return fetch(`/admin/api/iot/entity/${iot_entity_id}/set_swing_mode?swing_heat=${swing}`).then((response) => {
    })
  }
  swingMode() {
    return this.swingTarget.value
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['form'];

  connect() {
    $(".modal-backdrop").hide();
    $(this.element).modal('show');
    $(this.element).on('hidden.bs.modal', (event) => {
      const { target } = event;
      if (target && target.parentNode) target.parentNode.removeChild(target);
      $(".modal-backdrop").remove();
    });

    this.element.addEventListener("turbo:submit-end", (event) => {
      const { redirected } = event.detail.fetchResponse.response;
      if (event.detail.success && redirected) {
        this.destroy();
        Turbo.visit(event.detail.fetchResponse.response.url);
      }
    });
  }

  destroy() {
    $(this.element).modal('hide');
    $(this.element).on('hidden.bs.modal', (event) => {
      const { target } = event;
      if (target && target.parentNode) target.parentNode.removeChild(target);
      $(".modal-backdrop").remove();
    });
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault();
      this.element.addEventListener('hidden.bs.modal', event.detail.resume);
      this.modal.hide();
    }
  }

  isOpen() {
    return this.element.classList.contains("show");
  }
}
